import React from "react"
import { graphql } from "gatsby"

import GraphQLErrorList from "../components/graphql-error-list"
import SingleEntry from "../components/SingleEntry"
import SEO from "../components/seo"
import DefaultLayout from "../layouts/index"
export const query = graphql`
  query EntryTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      title
      abbreviation {
        current
      }

      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
    others: allSanityPost(filter: {}) {
      edges {
        node {
          id
          publishedAt
          title
          _rawBody
          _rawExcerpt
          slug {
            current
          }
          abbreviation {
            current
          }
        }
      }
    }
  }
`

const EntryTemplate = props => {
  const { data, errors } = props

  const post = data && data.post

  return (
    <DefaultLayout>
      {errors && <SEO title="GraphQL Error" location={props.location} />}
      {post && (
        <SEO title={post.title || "Untitled"} location={props.location} />
      )}

      {errors && (
        <div>
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {post && (
        <SingleEntry
          {...post}
          others={data.others}
          siteTitle={data.site.title}
        />
      )}
    </DefaultLayout>
  )
}

export default EntryTemplate
