import React, { useState } from "react"
import { Heading, theming, Wrapper, WidowFix } from "@staccx/base"
import styled, { css } from "styled-components"
import PortableText from "./portableText"
import PageLayout from "./PageLayout"
import Search from "./Search"

const SingleEntry = props => {
  const [isInputFocus, setIsInputFocus] = useState(null)
  const [hasResults, setHasResults] = useState(null)
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [pageColors, setPageColors] = useState([])

  const { _rawBody, siteTitle, others } = props

  const resultsCallback = val => {
    setHasResults(val)
  }

  const inputFocusCallback = val => {
    setIsInputFocus(val)
  }

  const searchModeCallback = val => {
    setIsSearchMode(val)
  }

  const colorsCallback = colors => {
    setPageColors(colors)
  }

  return (
    <PageLayout
      siteTitle={siteTitle}
      path={props.slug.current}
      className="PageLayout"
      colorsCallback={colorsCallback}
    >
      <SearchContainer
        isInputFocus={isInputFocus}
        isSearchMode={isSearchMode}
        hasResults={hasResults}
        pageColors={pageColors}
        className="SearchContainer"
      >
        <SearchWrapper
          isInputFocus={isInputFocus}
          isSearchMode={isSearchMode}
          className="SearchWrapper"
        >
          <Search
            posts={others}
            pageColors={pageColors}
            location={"single-entry"}
            inputFocusCallback={inputFocusCallback}
            searchModeCallback={searchModeCallback}
            resultsCallback={resultsCallback}
            className="Search"
          />
        </SearchWrapper>
      </SearchContainer>
      <article className="article-div">
        <StyledWrapper size={"mediumPlus"} className="StyledWrapper">
          <Content className="Content">
            {props.abbreviation ? (
              <>
                <MainTerm level={1}>
                  <WidowFix>{props.abbreviation.current}</WidowFix>
                </MainTerm>
                <AbbreviationDescription level={2}>
                  <WidowFix>{props.title}</WidowFix>
                </AbbreviationDescription>
              </>
            ) : (
              <MainTerm level={1}>
                <WidowFix>{props.title}</WidowFix>
              </MainTerm>
            )}
            <Description>
              <div>{_rawBody && <PortableText blocks={_rawBody} />}</div>
            </Description>
          </Content>
        </StyledWrapper>
      </article>
    </PageLayout>
  )
}

const SearchContainer = styled.div`
  position: absolute;
  top: -21px;
  right: 0;
  width: 100%;
  max-width: calc(410px + ${theming.spacing.small} * 2);
  /* (This ☝️ corresponds to padding left/right on input field in SearchInput) */
  height: calc(100vh - 36px + 21px);

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    height: auto;
    max-width: none;
    margin-top: 24px;
    ${p =>
      p.isSearchMode &&
      css`
        background-color: ${p => p.pageColors[0]};
        color: #333;
        position: fixed;
        left: 0;
        right: 0;
        margin-top: 0;
        z-index: 1;
        height: 100vh;
      `}
  }
`

const SearchWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 768px) {
    ${p =>
      p.isSearchMode &&
      css`
        position: relative;
        top: 10px;
        padding-left: 24px;
        padding-right: 24px;
        transform: translateY(-10px);
        transition: transform 300ms ease-out;
      `}
  }
`

const StyledWrapper = styled(Wrapper)`
  padding: ${theming.spacing.huge} 55px;

  @media (max-width: 768px) {
    padding-top: ${theming.spacing.large};
    padding-left: 40px;
    padding-right: 40px;
  }
`

const Content = styled.div``

const MainTerm = styled(Heading)`
  font-size: ${theming.font.huge};
  font-weight: bold;
  line-height: 1.1;

  @media (max-width: 768px) {
    font-size: ${theming.font.h2};
  }
`

const AbbreviationDescription = styled(Heading)`
  font-size: ${theming.font.huge};
  font-weight: normal;
  line-height: 1.1;

  @media (max-width: 768px) {
    font-size: ${theming.font.h2};
  }
`

const Description = styled.div`
  font-size: ${theming.font("description")};
  margin-top: ${theming.spacing.mediumPlus};

  p:not(:last-child):not(:empty) {
    margin-bottom: ${theming.spacing.medium};
  }

  @media (max-width: 768px) {
    font-size: ${theming.font.h3};
  }

  @media (max-width: 425px) {
    font-size: ${theming.font.input};
  }
`

export default SingleEntry
